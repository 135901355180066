/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO } from '@core';
import { Rapid } from '@composite';

const AboutPage = () => (
  <Layout invertedHeader>
    <StaticSEO pageId='rapid' />
    <Rapid />
  </Layout>
);

export default AboutPage;
